// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps, StepsStyleConfig } from "chakra-ui-steps";

const theme = {
  colors: {
    brand: {
      50: "#f0faf1",
      100: "#c3eac5",
      200: "#99d49c",
      300: "#80b283",
      400: "#739f75",
      500: "#618662",
      600: "#517153",
      700: "#415b43",
      800: "#374d38",
      900: "#283728",
    },
  },
  /* global background color */
  styles: {
    global: {
      body: {
        bg: "#f5f5f5",
      },
    },
  },
  components: {
    Steps: {
      ...StepsStyleConfig,
      baseStyle: (props) => ({
        ...StepsStyleConfig.baseStyle(props),
        stepIconContainer: {
          ...StepsStyleConfig.baseStyle(props).stepIconContainer,
          _activeStep: {
            ...StepsStyleConfig.baseStyle(props).stepIconContainer._activeStep,
            bg: "rgb(224, 231, 239)",
          },
        },
      }),
    },
    FormErrorMessage: {
      baseStyle: {
        color: "red.500",
        fontSize: "xs",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "medium",
      },
    },
    Badge: {
      baseStyle: {
        fontWeight: "medium",
        py: "1",
        px: "2",
        borderRadius: "md",
        textTransform: "regular",
      },
    },
  },
};

export default extendTheme(theme);
