import { gql } from "@apollo/client";

// Define the login mutation using the GraphQL `mutation` keyword
export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    customerAccessTokenCreate(input: { email: $email, password: $password }) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// Define the signup mutation using the GraphQL `mutation` keyword
export const SIGNUP_MUTATION = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    customerCreate(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      customer {
        id
        email
        firstName
        lastName
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// Define the request password reset mutation using the GraphQL `mutation` keyword
export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// Define the reset password mutation using the GraphQL `mutation` keyword
export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $resetToken: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    customerReset(
      resetToken: $resetToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

// Define the logout mutation using the GraphQL `mutation` keyword
export const LOGOUT_MUTATION = gql`
  mutation Logout($customerAccessToken: String!) {
    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
      deletedAccessToken
      deletedCustomerAccessTokenId
      userErrors {
        field
        message
      }
    }
  }
`;
