import React from "react";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./src/store";

let persistor = persistStore(store);

const description =
  "Wir holen Deine schmutzige Pferdeausrüstung ab, waschen sie innerhalb einer Woche und bringen sie wieder zurück zu Dir. Melde Dich jetzt an und genieße mehr Zeit mit Deinem Pferd statt mit der Reinigung.";

export const wrapWithProvider = ({ element }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "de",
        }}
        defaultTitle="Jetzt für ShinyBlanket anmelden"
      >
        <title>Jetzt für ShinyBlanket anmelden</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://my.shinyblanket.at" />
        <meta property="og:title" content="Jetzt für ShinyBlanket anmelden" />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://shinyblanket.at/wp-content/uploads/2023/01/OG-Image-ShinyBlanket-Configurator.jpg"
        />
        <meta property="og:image" content="/icon/og-image.jpg" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="my.shinyblanket.at" />
        <meta
          property="twitter:title"
          content="Jetzt für ShinyBlanket anmelden"
        />
        <meta property="twitter:description" content={description} />
        <meta
          property="twitter:image"
          content="https://shinyblanket.at/wp-content/uploads/2023/01/OG-Image-ShinyBlanket-Configurator.jpg"
        />
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistor}>{element}</PersistGate>
      </Provider>
    </>
  );
};
