//#region > Imports
//> Logger (Remove in production)
import logger from "redux-logger";
//> Thunks
import thunk from "redux-thunk";
// Redux Toolkit
import { combineReducers, configureStore } from "@reduxjs/toolkit";
// Persist Store
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

//> Slices
import authSlice from "./slices/auth.slice";
import shopifySlice from "./slices/shopify.slice";
//#endregion

//#region > Config
const middleware =
  process.env.REACT_APP_ENVIRONMENT !== "production"
    ? [logger, thunk]
    : [thunk];

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"], // only persist the "auth" slice
};

const rootReducer = combineReducers({
  auth: authSlice,
  shopify: shopifySlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});
//#endregion

//#region > Exports
export type RootState = ReturnType<typeof rootReducer>;

export default store;
//#endregion
