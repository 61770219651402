import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { shopifyClient } from "../../utils/shopifyClient";
import { ProductVariantEdge, Product } from "../../types/shopify";

export interface ShopifyState {
  products: Product[];
}

const initialState: ShopifyState = {
  products: [],
};

export const getAllProducts = createAsyncThunk(
  "shopify/products",
  async (_, thunkAPI) => {
    try {
      const response = await shopifyClient.getAllProducts();

      const products = response?.edges?.map(
        (product: { node: Product }) => product?.node
      );

      if (!products || products.length === 0) {
        return thunkAPI.rejectWithValue("No products found");
      }

      const productsWithVariants: Product[] = products.map((product: any) => {
        const variants = product?.variants?.edges?.map(
          (variant: ProductVariantEdge) => variant?.node
        );

        return {
          ...product,
          variants,
        };
      });

      return thunkAPI.fulfillWithValue(productsWithVariants);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message || null);
    }
  }
);

const shopifySlice = createSlice({
  name: "shopify",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(getAllProducts.rejected, (state, action) => {
      state.products = [];
    });
  },
});

export const {} = shopifySlice.actions;

export default shopifySlice.reducer;
