import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { shopifyClient } from "../../utils/shopifyClient";

interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  error: string | null;
}

const initialState: AuthState = {
  token: null,
  isAuthenticated: false,
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async (input: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await shopifyClient.createCustomerAccessToken(input);

      if (response.customerUserErrors.length > 0) {
        return thunkAPI.rejectWithValue(response.customerUserErrors[0].message);
      }

      return response?.customerAccessToken?.accessToken || null;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message || null);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    // Get the current state
    const state = thunkAPI.getState() as { auth: AuthState };
    const customerAccessToken = state.auth.token;

    if (!customerAccessToken) {
      return thunkAPI.rejectWithValue("No customer access token found");
    }

    await shopifyClient.deleteCustomerAccessToken(customerAccessToken);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.message || null);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.token = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutSuccess(state) {
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
    },
    logoutFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.token = null;
      state.isAuthenticated = false;
      //state.error = action.payload || null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      //state.error = action.payload || null;
    });
  },
});

export const { loginSuccess, loginFailure, logoutSuccess, logoutFailure } =
  authSlice.actions;

export default authSlice.reducer;
