import { gql } from "@apollo/client";

// Define the product query using the GraphQL `query` keyword
export const PRODUCTS_QUERY = gql`
  query {
    products(first: 50) {
      edges {
        node {
          id
          title
          description
          variants(first: 10) {
            edges {
              node {
                id
                title
                price {
                  amount
                  currencyCode
                }
                image {
                  originalSrc
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
