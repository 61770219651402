import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { LOGIN_MUTATION, LOGOUT_MUTATION } from "../graphql/mutations";
import { PRODUCTS_QUERY } from "../graphql/queries";

const client = new ApolloClient({
  link: new HttpLink({
    uri: "https://shinyblanket.myshopify.com/api/2022-10/graphql.json",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": "02ba7d6601aef74cb165f6c2f03acee7",
    },
  }),
  cache: new InMemoryCache(),
});

export const shopifyClient = {
  getAllProducts: async () => {
    const response = await client.mutate({
      mutation: PRODUCTS_QUERY,
    });

    return response.data.products;
  },
  createCustomerAccessToken: async (input: {
    email: string;
    password: string;
  }) => {
    const response = await client.mutate({
      mutation: LOGIN_MUTATION,
      variables: {
        email: input.email,
        password: input.password,
      },
    });

    return response.data.customerAccessTokenCreate;
  },
  deleteCustomerAccessToken: async (customerAccessToken: string) => {
    const response = await client.mutate({
      mutation: LOGOUT_MUTATION,
      variables: { customerAccessToken },
    });
    return response.data.customerAccessTokenDelete;
  },
};
